@import '../../../styles/variables';
@import '../../../styles/mixins';
@import './stack.desktop';
@import './stack.desktop.pi';
@import './gallery.desktop';
@import './gallery.desktop.pi';
@import './map.desktop';

.#{$search-prefix}-results,
.#{$search-prefix}-results--map {
  display: flex;
  flex-direction: column;
  margin-top: $andes-spacing-12;
  width: 100%;
}

.#{$search-prefix}-results {
  max-width: 884px;

  @media (width <= 1164px) and (width >= 1024px) {
    width: 744px;
  }

  @media (width <= 1023px) and (width >= 862px) {
    width: 584px;
  }

  @media (width <= 861px) {
    width: 492px;
  }
}

.#{$search-prefix}-layout {
  display: inline-flex;
}

@media (width <= 1164px) {
  .#{$search-prefix}-layout {
    justify-content: center;
    width: inherit;
  }
}

.#{$search-prefix}-result__highlight-container {
  padding-bottom: $andes-spacing-4;

  .#{$search-prefix}-item__highlight-label {
    font-size: $font-size-10;
    font-weight: $font-weight-semibold;
    padding: $search-spacing-2 $search-spacing-6;
    border-radius: $search-spacing-2;
    line-height: $line-height-base;
    margin: 0;

    &--TIME_LABEL_HIGHLIGHT {
      font-size: $font-size-12;
    }
  }
}

.#{$search-prefix}-layout--grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(240px, 1fr));
  gap: 16px;
  margin-bottom: 16px;
  width: 100%;
  justify-content: space-between;
  flex-grow: 0;
  margin-right: 0;

  @media (width <= 1023px) {
    grid-template-columns: repeat(2, minmax(238px, 1fr));
    gap: 10px;
    margin-right: 0;
  }

  .#{$search-prefix}-layout__item {
    margin-bottom: 0;
  }
}
