@import '../../../../styles/variables';

.#{$search-prefix}-applied-filters {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2px;
  width: 100%;
}

.#{$search-prefix}-applied-filters__link {
  margin-bottom: 10px;
  margin-right: 10px;

  & .andes-tag {
    display: flex;
    margin: 0;
    padding: 0;
    border-radius: 1px;
    max-height: 26px;
    background-color: $search-white-100;
    border-color: transparent;

    &__label {
      color: $search-gray-620;
      font-size: $font-size-13;
    }

    &__close {
      fill: $search-gray-600;
    }

    &__close:hover {
      fill: $search-gray-800;
    }
  }

  & .andes-tag {
    background-color: $search-white-100;
    border-radius: 2px;

    & .andes-tag__close {
      margin: 0 2px;
    }

    & .andes-tag__label {
      color: $search-gray-450;
      font-weight: $font-weight-light;
    }

    & .andes-tag__label:first-child {
      margin-left: 8px;
      margin-right: 3px;
    }
  }

  & .andes-tag__close:hover {
    background-color: unset;
  }

  & .andes-tag__close:focus {
    box-shadow: unset;
  }

  & .andes-tag__close-svg-group {
    fill: $search-gray-600;
  }

  & .andes-tag__close-svg-group:hover {
    fill: $search-gray-800;
  }

  & .andes-tag:hover .andes-tag__close-svg-group {
    fill: $search-gray-666;
  }
}
