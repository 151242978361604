@import '../../../styles/variables';

.#{$search-prefix}-tooltip {
  padding: $andes-spacing-16;
  border-radius: $border-radius-6;

  .andes-tooltip__title {
    font-size: $font-size-16;
    line-height: 1.15;
    margin: 0 0 5px;
    color: $andes-white;
  }

  .andes-tooltip__text {
    line-height: 1.3;
  }
}
