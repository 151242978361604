.#{$search-prefix}-map {
  &__link {
    margin-bottom: 20px;
    display: inline-block;
    font-size: $font-size-16;
    font-weight: $font-weight-semibold;
    line-height: $line-height-125;
    width: 40%;
  }

  &__icon-map {
    vertical-align: sub;
    margin-right: 4px;
  }
}
