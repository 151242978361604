@import '../../../styles/variables';

$desktop-height: 90px;
$tablet-height: 67px;

.#{$search-prefix}-header--exhibitor-pi {
  height: $desktop-height;

  &__image {
    display: block;
    height: $desktop-height;
    position: absolute;
    left: 0;
    width: 100%;
    object-fit: cover;
  }

  a {
    height: 100%;
    display: block;
  }
}

@media (width <= 1024px) {
  .#{$search-prefix}-header--exhibitor-pi {
    height: $tablet-height;

    .banner {
      height: $tablet-height;
    }
  }
}
