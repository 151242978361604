$result-image-height: 166px;
$result-image-width: 216px;

.#{$search-prefix}-layout--stack {
  &.#{$search-prefix}-layout--pi {
    padding: 0;

    .#{$search-prefix}-result__content-wrapper {
      width: 100%;
    }
  }

  & .andes-carousel-snapped__container {
    position: absolute;
  }

  .#{$search-prefix}-layout__item {
    .#{$search-prefix}-result-item-super_premium {
      position: absolute;
      bottom: 0;

      .real-estate-agency-logo__image--stack {
        background-color: $search-white-100;
        border: solid 1px $search-gray-93;
        border-radius: $andes-spacing-4;
        object-fit: contain;
        padding: $andes-spacing-4;
        width: 47px;
        height: 36px;
        float: left;
        margin-right: 12px;
        margin-top: 10px;
      }

      .real-estate-agency-name {
        font-size: $font-size-12;
        line-height: 1.25;
        color: $search-gray-450;
        margin-top: 20px;
        float: left;
      }
    }

    .real-estate-agency-onlyname {
      margin-top: $andes-spacing-8;
      font-size: $font-size-12;
      line-height: 1.25;
      color: $search-gray-450;
      float: left;
      position: relative;
      bottom: 0;
    }

    .#{$search-prefix}-result {
      &__image {
        box-sizing: content-box;
        margin-top: $search-spacing-14;
        border-radius: $border-radius-4;
        flex-shrink: 0;
        height: $result-image-height;
        margin: 0;
        overflow: hidden;
        padding: 0 $search-spacing-20;
        width: $result-image-width;
      }

      .#{$search-prefix}-item__subtitle {
        font-size: $font-size-12;
        color: $search-gray-450;
        margin-top: $andes-spacing-8;
        line-height: 1.25;
        margin-bottom: $andes-spacing-4;
      }

      .#{$search-prefix}-item__group--rental-type {
        order: 3;
        margin-top: $search-spacing-10;

        .#{$search-prefix}-item__highlight-group-hint:last-child {
          margin-bottom: $andes-spacing-4;
        }
      }

      .#{$search-prefix}-item__group--location {
        margin-bottom: 0;
        display: block;
        white-space: nowrap;
        opacity: 0.8;
        line-height: 1.29;

        .#{$search-prefix}-item__location,
        .#{$search-prefix}-item__information {
          width: 90%;
          font-weight: $font-weight-semi-bold-light;
          color: $search-gray-1000;
          font-size: $font-size-14;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .#{$search-prefix}-item__group--price {
        margin-bottom: 0;

        & + .#{$search-prefix}-item__group--rental-type {
          margin-top: $andes-spacing-12;
        }
      }

      .#{$search-prefix}-item__highlighted-wrapper {
        position: absolute;
        bottom: $search-spacing-14;
        right: $search-spacing-20;
        font-size: $font-size-12;
        color: $search-gray-450;
      }

      .#{$search-prefix}-price__second-line .price-tag-cents {
        line-height: unset;
      }

      .#{$search-prefix}-official-store-label {
        color: $search-gray-666;
        font-size: $font-size-12;
        font-weight: $font-weight-light;
        margin-top: $search-spacing-8;
        line-height: 1;

        &:hover {
          color: $search-gray-1000;
        }
      }

      .official-store--pi {
        width: 300px;
        margin-top: 20px;
      }
    }
  }

  @media (width >= 1165px) {
    .#{$search-prefix}-layout__item {
      .#{$search-prefix}-result-item-super_premium {
        order: 5;
        position: unset;
      }
    }
  }

  @media (width <= 1164px) and (width >= 1024px) {
    .#{$search-prefix}-layout--stack,
    .#{$search-prefix}-result {
      padding: 20px 20px 20px 0;
    }

    .#{$search-prefix}-result-item-super_premium {
      .real-estate-agency-logo__image--stack {
        width: 53px;
        height: 40px;
        position: absolute;
        right: 15px;
        bottom: 8px;
      }

      .real-estate-agency-onlyname {
        display: none;
      }

      .real-estate-agency-name {
        display: none;
      }
    }

    .#{$search-prefix}-card-attributes__attribute {
      font-size: $font-size-12;
    }
  }

  @media (width <= 1023px) and (width >= 862px) {
    .#{$search-prefix}-layout__item {
      .ui-search-layout--stack,
      .ui-search-result {
        padding: 20px 20px 20px 0;
      }

      .#{$search-prefix}-result-item-super_premium {
        .real-estate-agency-logo__image--stack {
          width: 53px;
          height: 40px;
          position: absolute;
          right: 15px;
          bottom: 8px;
        }

        .real-estate-agency-onlyname {
          display: none;
        }

        .real-estate-agency-name {
          display: none;
        }
      }

      .#{$search-prefix}-card-attributes__attribute {
        font-size: $font-size-12;
      }
    }
  }

  @media (width <= 861px) {
    .#{$search-prefix}-layout__item {
      .ui-search-layout--stack,
      .ui-search-result {
        padding: 20px 20px 20px 0;
      }

      .real-estate-agency-onlyname {
        position: unset;
        order: 5;
        padding-top: 5px;
      }

      .#{$search-prefix}-result-item-super_premium {
        .real-estate-agency-logo__image--stack {
          width: 53px;
          height: 40px;
          position: absolute;
          right: 15px;
          bottom: 8px;
        }

        .real-estate-agency-onlyname {
          display: none;
        }

        .real-estate-agency-name {
          display: none;
        }
      }

      .#{$search-prefix}-card-attributes__attribute {
        font-size: $font-size-12;
      }
    }
  }
}
