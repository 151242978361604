.#{$search-prefix}-map-button {
  &__container {
    margin: 11px 0 16px;
    display: inline-block;
    font-size: $font-size-16;
    font-weight: $font-weight-semibold;
    line-height: $line-height-125;
    width: 100%;
  }

  &__button {
    width: 242px;

    .andes-button__content {
      position: relative;

      path {
        stroke-width: 0.2;
      }
    }

    .ui-search-map-link {
      position: absolute;
      left: 41px;
    }
  }
}
