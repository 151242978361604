@import 'vis-faceted-search/src/desktop/index';
@import '../../../../styles/variables';

$field-height: 36px;
$field-box-shadow: 0 0 0 1px $search-gray-r010;

.#{$search-prefix}-results .#{$faceted-desktop-prefix} {
  margin-bottom: $search-spacing-16;
  padding: $search-spacing-10 $search-spacing-12;

  &__main-container {
    justify-content: center;
  }

  &__elem {
    margin: 0 $search-spacing-8 0 0;
  }

  &__elem:last-child {
    margin: 0;
  }

  .andes-floating-menu .andes-dropdown__trigger {
    box-shadow: $field-box-shadow;
  }

  // sass-lint:disable no-qualifying-elements
  .andes-floating-menu button.andes-dropdown__trigger {
    min-height: $field-height;
    padding-top: $search-spacing-8;
    padding-bottom: $search-spacing-8;

    &:focus:not(:focus-visible) {
      box-shadow: $field-box-shadow;
    }
  }

  .#{$faceted-desktop-prefix}-searchbox {
    .andes-form-control__control {
      min-height: $field-height;
      box-shadow: $field-box-shadow;
    }

    .andes-form-control--focused .andes-form-control__control {
      box-shadow: 0 0 0 2px $andes-blue-500;
    }

    .andes-form-control__control input {
      background: $andes-white;
      font-size: $font-size-14;
    }

    .andes-form-control__control input::placeholder {
      font-size: $font-size-14;
      color: $andes-gray-550;
    }

    &__icon {
      bottom: 0.3rem;
    }
  }

  .andes-checkbox__label.andes-checkbox__label-text {
    font-size: $font-size-12;
  }

  .andes-button.#{$faceted-desktop-prefix}__elem-actions {
    height: $field-height;
  }
}
