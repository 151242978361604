@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/mixins.desktop-small';

.#{$search-prefix}-item__online-visit-container-grid {
  margin: 8px 0 4px;
  display: flex;
  align-items: center;
  svg {
    fill: $andes-text-color-primary;
    margin-right: $andes-spacing-8;
  }
  .#{$search-prefix}-item__online-visit-label {
    font-size: $font-size-14;
    font-weight: $font-weight-semibold;
    color: $andes-text-color-primary;
    line-height: $line-height-18;
  }
}

.#{$search-prefix}-item__title-description-container {
  margin-bottom: $andes-spacing-12;
}

.#{$search-prefix}-item__rental-type-container {
  margin-top: $andes-spacing-8;
  margin-bottom: 5px !important;
}

.#{$search-prefix}-item__subtitle-grid {
  line-height: $line-height-15;
  font-size: $font-size-12;
  color: $search-gray-r050;
  font-weight: $font-weight-normal;
  margin-bottom: 3px;
}

.#{$search-prefix}-item__title-label-grid {
  font-size: $font-size-14;
  font-weight: $font-weight-normal;
  color: $search-gray-r080;
}

.#{$search-prefix}-item__location-container {
  margin-bottom: $andes-spacing-4;
  .#{$search-prefix}-item__location-label {
    font-size: $font-size-14;
    font-weight: $font-weight-normal;
    color: $search-gray-r050;
  }
}

.#{$search-prefix}-item__possession-container {
  .#{$search-prefix}-item__possession {
    padding-left: 0;
    font-size: $font-size-14;
    color: $search-gray-r050;
    font-weight: $font-weight-normal;
  }
}

.#{$search-prefix}-item__official-store-container {
  padding-left: $andes-spacing-16;
  margin-top: -10px;
  padding-bottom: $andes-spacing-16;
  .#{$search-prefix}-official-store-label {
    color: $search-gray-r050 !important;
    font-weight: $font-weight-normal !important;
    font-size: $font-size-14 !important;
  }
}

.#{$search-prefix}-item__attributes-grid-container {
  .#{$search-prefix}-card-attributes__attribute {
    font-size: $font-size-14 !important;
    color: $search-gray-r090 !important;
    font-weight: $font-weight-normal !important;
  }
}

.#{$search-prefix}-item__available-units-container-grid {
  margin-top: 0 !important;
  .#{$search-prefix}-item__available-units-label {
    font-size: $font-size-14;
    font-weight: $font-weight-semibold;
    color: $andes-gray-550;
  }
}

.#{$search-prefix}-item__attributes-grid-container {
  margin-top: 0 !important;
  margin-bottom: $andes-spacing-4 !important;
  .#{$search-prefix}-item__attributes-grid li {
    font-size: $font-size-14;
    font-weight: $font-weight-normal;
    color: $search-gray-r090;
    display: flex;
    list-style: none;
    flex-wrap: wrap;

    &:first-child::after {
      content: '';
      margin-left: 0;
    }

    &:nth-child(2)::after {
      content: '';
    }

    &:nth-child(2)::before {
      content: '|';
      vertical-align: baseline;
      margin-right: 5px;
      margin-left: 0;
      font-size: 100%;
    }

    &:nth-child(3) {
      flex: 0 0 100%;
    }
  }
}

// sass-lint:disable no-vendor-prefixes
.#{$search-prefix}-layout--grid,
.#{$search-prefix}-layout--gallery {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 0;
  margin-right: -$andes-spacing-16;

  // BASE CARD STYLES

  .#{$search-prefix}-layout__item {
    @include gallery-cards-width-medium-xsmall-screen;
    @include gallery-cards-sides-margins-2-cols-small-screen;
    @include gallery-cards-sides-margins-2-cols-xsmall-screen;
    @include gallery-cards-sides-margins-1-col-xxsmall-screen;

    display: flex;
    flex-basis: $gallery-cards-image-width;
    flex: 1 1;
    margin-bottom: $andes-spacing-16;
    min-width: $gallery-small-cards-image-width;
    max-width: $gallery-cards-image-width;
    width: 100%;
  }

  .#{$search-prefix}-result__wrapper {
    display: block;
    flex-grow: 1;
    flex-shrink: 0;
    width: 100%;
    height: calc(var(--min-content-height, auto) + var(--min-image-height, auto));
    z-index: 0;

    &.hidden {
      opacity: 0;
      pointer-events: none;
    }
  }

  .#{$search-prefix}-result {
    border-radius: $border-radius-6;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-height: 100%;
    height: 100%;
    position: relative;
    width: 100%;

    &:hover,
    &:focus {
      .andes-carousel-snapped__control {
        visibility: visible;
      }
    }

    .andes-carousel-snapped__control {
      visibility: hidden;

      &:hover,
      &:focus {
        box-shadow: none;
      }
    }

    .andes-carousel-snapped__control:focus-visible {
      @include add-focus-box-shadow;
    }

    .andes-carousel-snapped__slide {
      justify-content: center;

      .ui-search-link {
        width: 100%;
      }
    }

    .andes-carousel-snapped__container {
      .andes-carousel-snapped--scroll-hidden {
        margin: 0;
        padding: 0;
      }
    }

    &__image {
      border-radius: $border-radius-6 $border-radius-6 0 0;
      border-bottom: 1px solid $search-gray-1400;
      font-size: 1px;
      max-height: unset;
      margin: 0;
      position: relative;
      width: 100%;

      svg {
        width: 32px;
        height: 32px;
      }

      .ui-search-result-image__element {
        height: 100%;
        min-height: $gallery-cards-image-height;
        max-height: $gallery-cards-image-height;
      }

      &::after {
        animation: animation-indeterminate 2.5s linear infinite both;
        animation-name: none;
        background-color: $search-gray-700;
        bottom: 0;
        content: '';
        height: 1px;
        left: 0;
        position: absolute;
        width: 0;

        //z-index: 200;
      }

      &--loading::after {
        animation-name: animation-indeterminate;
      }

      .carousel-container {
        border-radius: $border-radius-6 $border-radius-6 0 0;
        overflow: hidden;
      }

      .carousel-container,
      .slick-list,
      .slick-track,
      .slick-slide {
        height: 100%;
        position: relative;
        width: 100%;

        // @include gallery-cards-image-height-medium-xsmall-screen();
      }

      .slick-slider {
        align-items: center;
        display: flex;
        height: 100%;
        position: relative;
        width: 100%;
      }

      .prev-button,
      .next-button {
        background-color: initial;
        box-shadow: initial;

        &::after,
        &::before {
          box-shadow: 0 1px $search-gray-100;
        }
      }

      .prev-button {
        left: 0;
        top: unset;
      }

      .next-button {
        right: 0;
        top: unset;
      }
    }

    &-image__element {
      height: 100%;
      width: 100%;
      object-fit: contain;
      object-position: center;
      border-radius: $search-spacing-6 $search-spacing-6 0 0;
    }

    &__content {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      justify-content: flex-start;
      padding: 0;
      position: relative;

      *:last-child {
        margin-bottom: 0;
      }
    }

    &__content-wrapper {
      display: flex;
      flex-direction: column;
      flex-grow: 0;
      padding: $search-spacing-22 $andes-spacing-20;
      position: relative;
    }

    // GROUPS

    .#{$search-prefix}-item__group {
      display: flex;
      flex-shrink: 0;
      width: 100%;
    }

    .#{$search-prefix}-item__group:last-child {
      margin-bottom: 0;
    }

    // BOTTOM MEDIA TAG
    .#{$search-prefix}-result__media-highlighted-bottom.flex-end {
      justify-content: flex-end;
      padding-right: $andes-spacing-16;
    }

    .#{$search-prefix}-result__media-highlighted-bottom {
      display: none;
      align-items: center;
      justify-content: space-between;
      background-color: $search-white-100;
      padding-right: 66px;
      padding-left: $andes-spacing-16;
      padding-bottom: $andes-spacing-12;
      .#{$search-prefix}-item__highlight-label {
        padding-bottom: 0;
      }
      .#{$search-prefix}-item__highlighted-grid {
        font-size: $andes-spacing-12;
      }
      .#{$search-prefix}-item__group--media-tag {
      }
      .#{$search-prefix}-item__highlighted-wrapper {
      }
    }

    // POSSESSION

    .#{$search-prefix}-item__possession-wrapper {
      display: none;

      .#{$search-prefix}-item__possession {
        font-size: $font-size-12;
        font-weight: $font-weight-regular;
        color: $search-gray-450;
        line-height: 15px;
        margin-top: 1px;
        margin-bottom: $andes-spacing-4;
        padding-left: 18px;
      }
    }

    //TITLE
    .#{$search-prefix}-item__group--title {
      .ui-search-link::before {
        content: '';
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
        left: 0;
      }
    }

    // PRICE

    .#{$search-prefix}-item__group--price {
      align-items: flex-start;
      flex-direction: column;
      margin-bottom: 13px;
    }

    .#{$search-prefix}-item__price_label {
      color: $search-gray-1000;
      display: flex;
      font-size: $font-size-24;
      font-weight: $font-weight-regular;
      margin-right: $search-spacing-10;
      line-height: 1;
    }

    .#{$search-prefix}-price {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &::before,
      &::after {
        content: unset;
      }

      &__part {
        color: $search-gray-1000;
        display: flex;
        font-size: $font-size-24;
        font-weight: $font-weight-regular;
        margin-right: $search-spacing-8;
        line-height: 1;

        .price-tag-symbol {
          margin-right: $search-spacing-6;
        }
      }

      &__original-value {
        align-items: center;
        color: $search-gray-700;
        font-size: $font-size-12;
        height: 8px;
        margin-bottom: $andes-spacing-8;

        .price-tag-symbol {
          margin-left: $search-spacing-2;
          margin-right: $andes-spacing-4;
        }
      }

      &__second-line {
        align-items: center;
        display: flex;
      }

      &__second-line__label {
        line-height: 1;
      }

      &__discount {
        color: $andes-success-green;
        display: flex;
        font-size: $font-size-14;
        font-weight: $font-weight-regular;
        line-height: 1;
      }
    }

    // INSTALLMENTS

    .#{$search-prefix}-installments {
      align-items: center;
      display: flex;
      flex-shrink: 0;
      font-size: $font-size-14;
      font-weight: $font-weight-regular;
      height: 10px;
      margin-top: $search-spacing-10;

      .#{$search-prefix}-price {
        &__second-line {
          margin: 0;
          margin-left: 3px;
          width: 50px;
        }

        &__part {
          color: inherit;
          display: flex;
          font-size: inherit;
          font-weight: inherit;
          margin: 0 0.3rem;
          line-height: 1;
        }

        &__part .price-tag-symbol {
          margin-right: 0.3rem;
        }
      }
    }

    // TITLE

    .#{$search-prefix}-item__group--title {
      flex-direction: column;
      flex-shrink: 0;
      margin-bottom: $andes-spacing-12;
    }

    .#{$search-prefix}-item__title {
      color: $search-gray-1000;
      display: flex;
      font-size: $font-size-14;
      font-weight: $font-weight-light;
      line-height: 1.3;
      margin-bottom: 2px;
      max-height: 36px;
    }

    // OFFICIAL STORE

    .#{$search-prefix}-official-store-label {
      color: $search-gray-666;
      font-size: $font-size-13;
      margin-top: $search-spacing-8;
      font-weight: $font-weight-light;
      line-height: 1;

      &:hover {
        color: $search-gray-1000;
      }
    }

    // ATTRIBUTES

    .#{$search-prefix}-item__group--attributes {
      margin-top: -$search-spacing-14;
      margin-bottom: $search-spacing-14;
    }

    .#{$search-prefix}-card-attributes__attribute {
      font-size: $font-size-12;
    }

    // AVAILABLE UNITS

    .#{$search-prefix}-item__group--available-units {
      font-size: $font-size-14;
      font-weight: $font-weight-semibold;
      color: $andes-gray-550;
      line-height: 18px;
      margin-top: $andes-spacing-8;
    }

    // BOOKMARK

    .#{$search-prefix}-result__bookmark {
      pointer-events: none;
      position: absolute;
      right: $search-spacing-8;
      top: $search-spacing-8;
      transition: opacity $quick-transition-duration;
      height: 30px;
      width: 30px;
      z-index: 6;

      &.#{$search-prefix}-result__bookmark-active {
        opacity: 1;
      }
    }

    .#{$search-prefix}-bookmark {
      opacity: 0;
      position: relative;
      z-index: $z-index-base;

      &--hover-disabled {
        opacity: 1;
      }

      &.#{$search-prefix}-bookmark--active {
        opacity: 1;
      }
    }

    .#{$search-prefix}-bookmark__btn {
      align-items: center;
      background: rgba($andes-white, 0.7);
      border: 0;
      border-radius: 50%;
      color: $andes-blue-500;
      content: '';
      cursor: pointer;
      display: flex;
      height: 30px;
      justify-content: center;
      width: 30px;
    }

    .#{$search-prefix}-bookmark__icon-bookmark,
    .#{$search-prefix}-bookmark__icon-bookmark-fill {
      position: absolute;
      height: 15px;
      width: 16px;
      z-index: $z-index-s;
      margin: 0;
      top: unset;
    }

    .#{$search-prefix}-bookmark__icon-bookmark {
      fill: transparent;
      stroke: currentcolor;
      stroke-width: 2px;
      top: $search-spacing-9;
      right: $search-spacing-12;
    }

    .#{$search-prefix}-bookmark__icon-bookmark-fill {
      fill: currentcolor;
      stroke: transparent;
      opacity: 0;

      &--active {
        opacity: 1;
      }
    }

    // LOCATION

    .#{$search-prefix}-item__group--location {
      margin-bottom: 0;
      display: block;
      font-size: 14px;
      font-weight: 400;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.29;
      letter-spacing: normal;
      color: $search-gray-r080;

      .#{$search-prefix}-item__location,
      .#{$search-prefix}-item__information {
        font-weight: $font-weight-semi-bold-light;
        color: $search-gray-1000;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }

    // DETAILS

    .#{$search-prefix}-item__details {
      color: $search-details-color;
      font-size: $font-size-14;
      font-weight: $font-weight-semi-bold-light;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  // HOVER STYLES

  .#{$search-prefix}-layout__item {
    &:hover {
      position: relative;
      z-index: 11;

      .#{$search-prefix}-result {
        height: 100%;
      }

      .#{$search-prefix}-item__group--reviews {
        display: flex;
      }

      .#{$search-prefix}-item__ad_label {
        display: flex;
      }

      .#{$search-prefix}-item__title {
        max-height: initial;

        //  -webkit-line-clamp: initial !important;
      }

      .#{$search-prefix}-item__location {
        max-height: initial;
        -webkit-line-clamp: initial !important;
      }

      .#{$search-prefix}-item__details {
        max-height: initial;

        // -webkit-line-clamp: initial !important;
        display: block;
      }

      .#{$search-prefix}-bookmark {
        pointer-events: initial;
        opacity: 1;
      }

      .#{$search-prefix}-result__media-highlighted-bottom {
        display: flex;
        padding-top: $andes-spacing-12;
        .#{$search-prefix}-item__highlighted-wrapper {
          color: #3483fa;
        }
      }
    }
  }

  .#{$search-prefix}-bookmark__btn {
    &:hover {
      color: $search-blue-600;
    }
  }

  //  PI CARD STYLES

  .#{$search-prefix}-result--res {
    .#{$search-prefix}-result__content {
      display: block;
      background-color: $andes-white;
      border-radius: 0 0 $search-spacing-6 $search-spacing-6;
    }
    .#{$search-prefix}-result__content.no-borders {
      border-radius: 0;
    }

    .#{$search-prefix}-result__content-wrapper {
      overflow: hidden;
      padding: 16px;
    }

    .#{$search-prefix}-result__image {
      .#{$search-prefix}-result-image__element {
        object-fit: cover;
        border-radius: $search-spacing-6 $search-spacing-6 0 0;
      }
    }

    .#{$search-prefix}-price__front-price,
    .#{$search-prefix}-item__subtitle {
      color: $search-gray-1000;
      display: flex;
      font-size: $font-size-12;
      font-weight: $font-weight-regular;
      margin-top: -2px;
    }

    .#{$search-prefix}-item__group--attributes {
      margin-top: -$search-spacing-18;
    }

    .#{$search-prefix}-item__group--price {
      margin-bottom: $search-spacing-18;
    }

    .#{$search-prefix}-item__group--title {
      margin-bottom: $andes-spacing-16;
    }

    .#{$search-prefix}-item__subtitle {
      margin-bottom: 2px;
      color: $search-gray-450;
      line-height: $line-height-15;
    }

    .#{$search-prefix}-item__title {
      font-size: $font-size-18;
      font-weight: $font-weight-semi-bold-light;
      margin-bottom: unset;
      line-height: 1;
      overflow: hidden;
    }

    .real-estate-agency-logo__image--grid {
      background-color: $search-white-100;
      border: solid 1px $search-gray-93;
      border-radius: $andes-spacing-4;
      object-fit: contain;
      padding: $andes-spacing-4;
      width: 53px;
      height: 40px;
      min-height: 40px;
      position: absolute;
      right: $andes-spacing-16;
      bottom: -$andes-spacing-20;
      z-index: 10;
    }
  }

  @media (width <=1024px) {
    .#{$search-prefix}-layout__item {
      margin-right: 0;

      &:nth-child(3n + 1) {
        margin-left: 0;
      }

      .#{$search-prefix}-bookmark {
        opacity: 1;

        &.#{$search-prefix}-bookmark--active {
          opacity: 1;
        }
      }
    }
  }
}

@keyframes animation-indeterminate {
  0% {
    left: 0;
    width: 5%;
  }

  50% {
    left: 15%;
    width: 50%;
  }

  100% {
    left: 100%;
    width: 0;
  }
}
