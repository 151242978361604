@import '../../../styles/variables';

.andes-dropdown--small {
  button {
    order: unset;
  }

  & .andes-dropdown__trigger {
    border: none;
    color: $andes-gray-550;
    padding-left: 0;
    margin: 0;
    text-align: left;
    display: flex;
    justify-content: space-between;
    width: 120px;
  }

  & .andes-dropdown__trigger:hover {
    color: $andes-gray-900;
  }

  & .andes-dropdown__trigger:focus {
    box-shadow: none;
  }

  & .andes-dropdown__arrow {
    font-size: $font-size-20;
    transform: rotate(0deg);
    transition: transform 0.3s ease-in;
    margin-top: 2px;
    margin-left: 0;
  }

  & .andes-dropdown__arrow::after {
    border-right: 0.1111em solid $search-dodger-blue;
    border-bottom: 0.1111em solid $search-dodger-blue;
  }

  &.andes-dropdown--open .andes-dropdown__arrow {
    transform: rotate(180deg);
    transition: transform 0.3s ease-out;
  }

  & .andes-list__item-primary {
    color: $andes-gray-550;
  }

  & .andes-list__item--selected {
    pointer-events: none;

    .andes-list__item-primary {
      color: $search-dodger-blue;
    }
  }

  & .andes-dropdown__popover {
    padding: 0;
    top: -0.7em;
  }
}

.andes-list {
  font-size: $font-size-14;
  font-weight: $font-weight-light;
  color: $andes-gray-550;

  & li {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      background: $search-blue-dropdown-nonselected;
      left: 0;
      height: 100%;
      z-index: 0;
    }

    &:hover::after {
      width: 0;
    }
  }

  &__item--selected::before {
    border-left: 2px solid $search-dodger-blue;
    left: 3px;
  }

  &__item-image-container {
    padding: 1.5px 5px;
  }
}

.#{$search-prefix}-sort-filter .andes-dropdown--small {
  & .andes-list {
    & .andes-list__item,
    & .andes-list__item + .andes-list__item {
      border-bottom: none;
    }
  }

  & .andes-dropdown__popover {
    margin-left: -10px;
    max-height: 250px;
    padding: $andes-spacing-4 0;
    max-width: 134px;
    min-width: 134px;
  }

  .andes-dropdown__trigger {
    box-shadow: none;
  }
}
